import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  const { config, siteMeta } = useStaticQuery(graphql`
    query getSeoData {
      config: site {
        siteMetadata {
          author
          description
          robots
          siteName
          siteUrl
          title
        }
      }
      siteMeta: markdownRemark(
        fileAbsolutePath: { glob: "**/content/site-meta.md" }
      ) {
        frontmatter {
          siteSeoImage {
            childImageSharp {
              gatsbyImageData(
                formats: [PNG]
                height: 630
                layout: FIXED
                placeholder: NONE
                quality: 85
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
                width: 1200
              )
            }
          }
        }
      }
    }
  `);

  return { config, siteMeta };
};
