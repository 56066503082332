import React, { useRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useWindowScroll } from "react-use";
import clsx from "clsx";

import { colors } from "@/theme/colors";

import {
  hasMessageStyles,
  baseStyles,
  useBannerContext,
} from "@/components/banner";

const PREFIX = "TableOfContents";

const classes = {
  content: `${PREFIX}-content`,
  stickToTop: `${PREFIX}-stickToTop`,
  hasMessage: `${PREFIX}-hasMessage`,
  stickToBottom: `${PREFIX}-stickToBottom`,
};

const TableOfContentsStyled = styled("div")(({ theme }) => ({
  marginTop: 12,
  [theme.breakpoints.up(700)]: {
    maxWidth: width,
    width,
  },

  [`& .${classes.content}`]: {
    ...baseStyles,
    [theme.breakpoints.up(700)]: {
      maxWidth: width,
      paddingRight: 50,
    },
    "& ul": {
      listStyle: "none",
      padding: 0,
      "& p": {
        margin: 0,
      },
    },
    "& > ul > li": {
      padding: "0.3em 0",
      "& > p > a, & > a": {
        color: colors.gray10,
        fontWeight: 500,
      },
      "& > ul > li": {
        marginLeft: 14,
        padding: "0.1em 0",
      },
      "& a": {
        color: colors.gray10,
        display: "block",
        [theme.breakpoints.down(768)]: {
          paddingTop: 6,
          paddingBottom: 6,
        },
        "&:hover": {
          color: colors.mintGreen,
          opacity: 1,
        },
      },
    },
  },

  [`& .${classes.stickToTop}`]: {
    [theme.breakpoints.up(700)]: {
      position: "fixed",
      top: 30,
    },
  },

  [`& .${classes.hasMessage}`]: {
    ...hasMessageStyles,
  },

  [`& .${classes.stickToBottom}`]: {
    [theme.breakpoints.up(700)]: {
      position: "absolute",
      bottom: bottomStickyPosition,
    },
  },
}));

export const width = 306;
const bottomStickyPosition = 26;

const TableOfContents = ({ data }) => {
  const scroll = useWindowScroll();
  const ref = useRef(undefined);
  const [stickToBottom, setStickToBottom] = useState();
  const [stickToTop, setStickToTop] = useState();
  const { hasMessage } = useBannerContext();

  useEffect(() => {
    const fromTop =
      window.pageYOffset + ref.current?.getBoundingClientRect().top;
    const childHeight = ref.current?.children[0].offsetHeight;
    const maxScroll =
      ref.current.offsetHeight - childHeight - bottomStickyPosition + fromTop;

    setStickToBottom(scroll.y > maxScroll);
    setStickToTop(ref.current && scroll.y > fromTop && scroll.y <= maxScroll);
  }, [scroll]);

  return (
    <TableOfContentsStyled ref={ref}>
      <div
        className={clsx(
          classes.content,
          stickToTop && classes.stickToTop,
          stickToBottom && classes.stickToBottom,
          hasMessage && classes.hasMessage
        )}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </TableOfContentsStyled>
  );
};

export default TableOfContents;
