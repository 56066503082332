import React, { useMemo } from "react";

import { useSiteMetadata } from "@/hooks/useSiteMetadata";

interface SEOProps {
  author: any;
  children?: any;
  description: any;
  featureImage?: any;
  metaDescription: any;
  pathname: any;
  robots: any;
  schemaOrg: any;
  seoImage: any;
  title: any;
  titleTag: any;
}

export const SEO = ({
  author,
  children,
  description,
  featureImage,
  metaDescription,
  pathname,
  robots,
  schemaOrg,
  seoImage,
  title,
  titleTag,
}: SEOProps) => {
  const { config, siteMeta } = useSiteMetadata();

  const seo = {
    author: author || config.siteMetadata.author,
    description:
      metaDescription || description || config.siteMetadata.description,
    image: useMemo(() => {
      const image =
        seoImage?.childImageSharp.gatsbyImageData.images.fallback.src ||
        featureImage?.childImageSharp.gatsbyImageData.images.fallback.src ||
        siteMeta.frontmatter.siteSeoImage.childImageSharp.gatsbyImageData.images
          .fallback.src ||
        "";

      return !image.startsWith("http")
        ? `${config.siteMetadata.siteUrl}${image}`
        : image;
    }, [config, siteMeta, seoImage, featureImage]),
    robots: robots || config.siteMetadata.robots || null,
    siteName: config.siteMetadata.siteName,
    title: titleTag || title || config.siteMetadata.title,
    titlePostfix:
      !titleTag || titleTag === "" ? ` | ${config.siteMetadata.title}` : "",
    url: `${config.siteMetadata.siteUrl}${pathname}`,
  };

  const jsonLdSchema = useParsedSchema(schemaOrg, {
    description: seo.description,
    image: seo.image,
    name: seo.title,
    url: pathname,
  });

  return (
    <>
      <title>{seo.title + seo.titlePostfix}</title>

      <meta name="viewport" content="initial-scale=1, width=device-width" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:image" content={seo.image} />
      <meta name="og:site_name" content={seo.siteName} />
      <meta name="og:title" content={seo.title + seo.titlePostfix} />
      <meta name="og:type" content="website" />
      <meta name="og:url" content={seo.url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={seo.author} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:site" content={seo.siteName} />
      <meta name="twitter:title" content={seo.title + seo.titlePostfix} />
      <meta name="twitter:url" content={seo.url} />
      {seo.robots ? <meta name="robots" content={seo.robots} /> : null}

      <link href="/fonts/roboto.min.css" rel="stylesheet" />
      <link href="/fonts/material-icons.min.css" rel="stylesheet" />

      {children}

      {jsonLdSchema && (
        <script type="application/ld+json">{jsonLdSchema}</script>
      )}
    </>
  );
};

const useParsedSchema = (
  userSchemaBase = "",
  { url, description, name, image }
) => {
  const baseSchema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url,
    description,
    name,
    image,
  };

  try {
    const userSchema = JSON.parse(userSchemaBase);
    return JSON.stringify({ ...baseSchema, ...userSchema }, undefined, 2);
  } catch (error) {
    return JSON.stringify(baseSchema, undefined, 2);
  }
};
