import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useStaticQuery, graphql } from "gatsby";

import CollectionForm from "./collection-form";
import ThankYou from "../thank-you";
import { colors } from "@/theme/colors";

const PREFIX = "CollectEmail";

const classes = {
  content: `${PREFIX}-content`,
};

const CollectEmailStyled = styled("div")(({ theme }) => ({
  background: colors.gray04,
  padding: "1em 4em",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },

  [`& .${classes.content}`]: {
    maxWidth: 600,
    margin: "0 auto",
    "& > *": {
      padding: "1em",
    },
  },
}));

const CollectEmail = ({ children }) => {
  const [collected, setCollected] = useState(false);

  const { site } = useStaticQuery(graphql`
    query getCollectEmailData {
      site: markdownRemark(
        fileAbsolutePath: { glob: "**/content/site-meta.md" }
      ) {
        frontmatter {
          collectEmail {
            form {
              primary
              secondary
            }
            thankYou {
              primary
              secondary
            }
          }
        }
      }
    }
  `);

  const { form, thankYou } = site.frontmatter.collectEmail;

  return (
    <CollectEmailStyled>
      <div className={classes.content}>
        {children || (
          <>
            {collected ? (
              <ThankYou
                primary={thankYou.primary}
                secondary={thankYou.secondary}
              />
            ) : (
              <CollectionForm
                primary={form.primary}
                secondary={form.secondary}
                onCollect={() => setCollected(true)}
              />
            )}
          </>
        )}
      </div>
    </CollectEmailStyled>
  );
};

export default CollectEmail;
