import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";

import { baseStyles, hasMessageStyles } from "@/components/banner";
import { GraphQLQuery } from "@/types/GraphQLQuery";
import { SectionContent } from "@/components/section";
import CollectEmail from "@/components/collect-email/index";
import { HeroDownload } from "@/components/HeroDownload";
import { Markdown } from "@/components/markdown";
import TableOfContents, { width } from "./table-of-contents";

const PREFIX = "Content";

const classes = {
  content: `${PREFIX}-content`,
  hasTableOfContents: `${PREFIX}-hasTableOfContents`,
  hasMessage: `${PREFIX}-hasMessage`,
  collectEmail: `${PREFIX}-collectEmail`,
};

const ContentStyled = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  margin: "0 auto",
  maxWidth: 1280,
  position: "relative",
  width: "90%",
  [theme.breakpoints.up(700)]: {
    justifyContent: "space-around",
    "@supports not (-ms-ime-align: auto)": {
      justifyContent: "space-evenly",
    },
  },
  paddingBottom: 30,

  [`& .${classes.content}`]: {
    ...baseStyles,
    maxWidth: `calc(100% - ${width}px)`,
    [theme.breakpoints.down(700)]: {
      maxWidth: "100%",
    },
  },

  [`& .${classes.hasTableOfContents}`]: {
    margin: 0,
  },

  [`& .${classes.hasMessage}`]: {
    ...hasMessageStyles,
    paddingBottom: 30,
  },

  [`& .${classes.collectEmail}`]: {
    paddingBottom: "3em",
    paddingTop: "3em",
  },
}));

export interface ContentProps {
  collectEmail: boolean;
  download: {
    callToAction: {
      text: string;
      link: string;
    };
    image: GraphQLQuery;
    headline: string;
    text: string;
  };
  hasMessage: boolean;
  html: string;
  markdownAppFrame: boolean;
  tableOfContents: GraphQLQuery;
}

export const Content = ({
  collectEmail,
  download,
  hasMessage,
  html,
  markdownAppFrame,
  tableOfContents,
}: ContentProps) => {
  return (
    <ContentStyled>
      {tableOfContents && <TableOfContents data={tableOfContents} />}
      <SectionContent
        className={clsx(
          classes.content,
          tableOfContents && classes.hasTableOfContents,
          hasMessage && classes.hasMessage
        )}
      >
        <article>
          <Markdown
            component="section"
            html={html}
            appFrame={markdownAppFrame}
          />
        </article>
        {collectEmail && (
          <div className={classes.collectEmail}>
            <CollectEmail>
              {download && <HeroDownload {...download} />}
            </CollectEmail>
          </div>
        )}
      </SectionContent>
    </ContentStyled>
  );
};
