import React from "react";
import { styled } from "@mui/material/styles";
import { graphql } from "gatsby";

import Layout from "@/layout";
import { Content } from "@/page-components/default/content";
import { Hero } from "@/components/Hero";
import { SEO } from "@/layout/SEO";
import { useBannerContext } from "@/components/banner";
import { height } from "@/components/banner/container";

const PREFIX = "PageLayout";

const classes = {
  hero: `${PREFIX}-hero`,
};

const PageLayoutStyled = styled("div")(({ hasBanner }) => ({
  [`& .${classes.hero}`]: {
    marginBottom: "4em",
    marginTop: hasBanner ? 60 + height : 60,
    transition: "all 0.33s",
  },
}));

const PageLayout = ({ data, pageContext, location }) => {
  const { hasMessage } = useBannerContext();

  const {
    description,
    download,
    markdownAppFrame,
    tableOfContents,
    title,
  } = data.markdownRemark.frontmatter;

  const { footerCta, collectEmail = true } = pageContext;

  return (
    <Layout noStick footerCta={footerCta} manualBanner>
      <PageLayoutStyled hasBanner={hasMessage}>
        <Hero
          background="deepPurple"
          backgroundImage="secondary"
          className={classes.hero}
          color="white01"
          download={download}
          headline={title}
          text={description}
        />
        <Content
          collectEmail={collectEmail}
          hasMessage={hasMessage}
          html={data.markdownRemark.html}
          markdownAppFrame={markdownAppFrame}
          tableOfContents={
            tableOfContents === false
              ? undefined
              : data.markdownRemark.tableOfContents
          }
        />
      </PageLayoutStyled>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...SeoFields
        datePublished(formatString: "MMMM DD, YYYY")
        tableOfContents
        markdownAppFrame
        download {
          headline
          text
          callToAction {
            text
            link
          }
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 85
                width: 650
              )
            }
          }
        }
      }
      html
      tableOfContents
      fields {
        slug
      }
    }
  }
`;

export const Head = ({ location, data, params, pageContext }) => {
  const {
    author,
    description,
    metaDescription,
    robots,
    schemaOrg,
    seoImage,
    title,
    titleTag,
  } = data.markdownRemark.frontmatter;

  return (
    <SEO
      author={author}
      description={description}
      metaDescription={metaDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={titleTag}
    />
  );
};

export default PageLayout;
