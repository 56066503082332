import React from "react";
import { styled } from "@mui/material/styles";
import { Button, TextField, Typography } from "@mui/material";

import { FormContainer, useFormState } from "@/components/netlify-form";
import { colors } from "@/theme/colors";

const PREFIX = "CollectionForm";

const classes = {
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  form: `${PREFIX}-form`,
  emailField: `${PREFIX}-emailField`,
  submitButton: `${PREFIX}-submitButton`,
};

const CollectionFormStyled = styled("div")(() => ({
  [`& .${classes.primary}`]: {
    marginTop: "1em",
  },

  [`& .${classes.secondary}`]: {
    padding: "1em",
  },

  [`& .${classes.form}`]: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-around",
    "@supports not (-ms-ime-align: auto)": {
      justifyContent: "space-evenly",
    },
  },

  [`& .${classes.emailField}`]: {
    width: "70%",
    margin: "20px 0",
    "& fieldset": {
      overflow: "hidden",
    },
    "& input": {
      backgroundColor: colors.white01,
    },
  },

  [`& .${classes.submitButton}`]: {
    margin: "24px 0",
  },
}));

const CollectionForm = ({ onCollect, primary, secondary }) => {
  const [formState, handleChange] = useFormState({ email: "" });

  return (
    <CollectionFormStyled>
      <div>
        <Typography variant="h5" component="h3" className={classes.primary}>
          {primary}
        </Typography>
        <Typography className={classes.secondary}>{secondary}</Typography>
      </div>
      <FormContainer
        className={classes.form}
        formName="collect-email"
        onSubmit={() => {
          if (onCollect) {
            onCollect();
          }
        }}
        formState={formState}
      >
        <TextField
          name="email"
          label="Email"
          type="email"
          onChange={handleChange}
          required
          className={classes.emailField}
        />
        <Button
          className={classes.submitButton}
          color="primary"
          type="submit"
          variant="contained"
        >
          Get updates
        </Button>
      </FormContainer>
    </CollectionFormStyled>
  );
};

export default CollectionForm;
